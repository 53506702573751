import api from "state/api";
import { getConfigWithBearerToken, notifyMsg } from "state/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAutomationsApi = createAsyncThunk("jobs/getAutomations", async () => {
  try {
    const response = await api.get(`/jobs/config`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getAutomationsApi:", error.message);
  }
});

export const updateAutomationsApi = createAsyncThunk("jobs/updateAutomations", async (payload) => {
  try {
    const response = await api.put(
      "/jobs/config",
      payload,
      getConfigWithBearerToken()
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updateAutomationsApi:", error.message);
  }
});

export const getAnalzyerInfoApi = createAsyncThunk("jobs/getAnalzyer", async () => {
  try {
    const response = await api.get(`/jobs/analyzer`, getConfigWithBearerToken());
    return response.data;
  } catch (error) {
    console.error("Error getAnalzyerInfoApi:", error.message);
  }
});

export const updateAnalzyerInfoApi = createAsyncThunk("jobs/updateAnalzyer", async (payload) => {
  try {
    const response = await api.put(
      "/jobs/analyzer",
      payload,
      getConfigWithBearerToken()
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updateAnalzyerInfoApi:", error.message);
  }
});

export const initializeAnalyzerApi = createAsyncThunk("jobs/initializeAnalyzer", async ({ file, ...payload }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    Object.keys(payload).forEach(key => {
      formData.append(key, payload[key]);
    });

    const response = await api.post(
      "/jobs/analyzer/initialize",
      formData,
      {
        headers: {
          ...getConfigWithBearerToken().headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error initializeAnalyzerApi:", error.message);
  }
});

export const getAnalyzerLogsApi = createAsyncThunk("suppliers/getAnalyzerLogs", async ({ page, pageSize }) => {
  try {
    const response = await api.get(
      `/jobs/analyzer/logs?page=${page}&pageSize=${pageSize}`,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error getAnalyzerLogsApi:", error.message);
  }
});

export const deleteAnalyzerLogApi = createAsyncThunk("suppliers/deleteAnalyzerLog", async ({ logId }) => {
  try {
    const response = await api.delete(
      `/jobs/analyzer/log/${logId}`,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error deleteAnalyzerLogApi:", error.message);
  }
});

export const downloadAnalyzerReportApi = createAsyncThunk("suppliers/downloadAnalyzerReport", async ({ logId }) => {
  try {
    const response = await api.get(`/jobs/analyzer/report/${logId}`, {
      responseType: 'blob',
      ...getConfigWithBearerToken()
    });

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `report-${logId}`;
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloadAnalyzerReportApi:", error.message);
  }
});

export const downloadAnalyzerPushResultApi = createAsyncThunk("suppliers/downloadAnalyzerPushResult", async ({ logId, resultId }) => {
  try {
    const response = await api.get(`/jobs/analyzer/push-result/${logId}/${resultId}`, {
      responseType: 'blob',
      ...getConfigWithBearerToken()
    });

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `push-result-${resultId}-${logId}`;
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloadAnalyzerPushResultApi:", error.message);
  }
});

export const getSearchTitleFormatsApi = createAsyncThunk("listings/getSearchTitleFormats", async () => {
  try {
    const response = await api.get(
      `/jobs/analyzer/title-formats`,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error getSearchTitleFormatsApi:", error.message);
  }
});

export const addSearchTitleFormatsApi = createAsyncThunk("listings/addSearchTitleFormats", async (titleFormat) => {
  try {
    const response = await api.post(
      `/jobs/analyzer/title-formats`,
      { titleFormat },
      getConfigWithBearerToken()
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error addSearchTitleFormatsApi:", error.message);
  }
});

export const deleteSearchTitleFormatsApi = createAsyncThunk("listings/deleteSearchTitleFormats", async ({ categoryId }) => {
  try {
    const response = await api.delete(
      `/jobs/analyzer/title-formats/${categoryId}`,
      getConfigWithBearerToken()
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleteSearchTitleFormatsApi:", error.message);
  }
});

export const pushAnalyzerReportApi = createAsyncThunk("listings/pushAnalyzerReport", async ({ marketplace, logId, options }) => {
  try {
    const response = await api.post(
      `/jobs/analyzer/push-report/${logId}?marketplace=${marketplace}`,
      { options },
      getConfigWithBearerToken()
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error pushAnalyzerReportApi:", error.message);
  }
});

export const getAnalzyerScheduleApi = createAsyncThunk("listings/getAnalzyerSchedule", async () => {
  try {
    const response = await api.get(
      `/jobs/analyzer/schedule`,
      getConfigWithBearerToken()
    );
    return response.data;
  } catch (error) {
    console.error("Error getAnalzyerScheduleApi:", error.message);
  }
});

export const updateAnalzyerScheduleApi = createAsyncThunk("listings/updateAnalzyerSchedule", async (payload) => {
  try {
    const response = await api.put(
      `/jobs/analyzer/schedule`,
      payload,
      getConfigWithBearerToken()
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error updateAnalzyerScheduleApi:", error.message);
  }
});

export const analyzerSpecificItemsFormatApi = createAsyncThunk("listings/analyzerSpecificItemsFormat", async () => {
  try {
    const response = await api.get(
      `/jobs/analyzer/specific-items-format`,
      {
        responseType: 'blob',
        ...getConfigWithBearerToken()
      }
    );

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "analyzer-specific-items-format.csv";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error analyzerSpecificItemsFormatApi:", error.message);
  }
});

export const itemDimensionsFileFormatApi = createAsyncThunk("listings/itemDimensionsFileFormat", async () => {
  try {
    const response = await api.get(
      `/jobs/analyzer/item-dimensions-format`,
      {
        responseType: 'blob',
        ...getConfigWithBearerToken()
      }
    );

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "dimensions-format.csv";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error itemDimensionsFileFormatApi:", error.message);
  }
});

export const itemDimensionsUploadApi = createAsyncThunk("jobs/itemDimensionsUpload", async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(
      "/jobs/analyzer/item-dimensions-upload",
      formData,
      {
        headers: {
          ...getConfigWithBearerToken().headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error itemDimensionsUploadApi:", error.message);
  }
});

export const initializeReportingApi = createAsyncThunk("listings/initializeReporting", async (payload) => {
  try {
    const response = await api.post(
      `/jobs/config/initialize-reporting`,
      payload,
      getConfigWithBearerToken()
    );
    notifyMsg(response.data);
    return response.data;
  } catch (error) {
    console.error("Error initializeReportingApi:", error.message);
  }
});

export const downloadReportingApi = createAsyncThunk("listings/downloadReporting", async (fileType) => {
  try {
    const response = await api.get(
      `/jobs/config/download-reporting/${fileType}`,
      {
        responseType: 'blob',
        ...getConfigWithBearerToken()
      }
    );

    const blob = new Blob([response.data], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileType;
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloadReportingApi:", error.message);
  }
});
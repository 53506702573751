import React, { useState, useEffect } from "react";
import moment from "moment-timezone";

import socket from "app/socket";

import {
  Box,
  Button,
  Typography,
  LinearProgress,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  TextField
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  Download
} from "@mui/icons-material";

import Header from "components/Header";
import DialogBox from "components/DialogBox";
import ProgressBar from "components/ProgressBar";

import {
  getEbayApiRateLimitApi
} from "features/settings/settingsApi";
import { getStoresApi } from "features/stores/storesApi";
import {
  initializeReportingApi,
  downloadReportingApi
} from "features/jobs/jobsApi";

import { useDispatch, useSelector } from "react-redux";

const Dashboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { storeIdPairs } = useSelector((state) => state.stores);
  const { downloadReportingStatus } = useSelector((state) => state.jobs);
  const {
    ebayApiRateLimitApi
  } = useSelector((state) => state.settings);

  const [tradingApiRateLimit, setTradingApiRateLimit] = useState({
    limit: "------",
    remaining: "------"
  });
  const [browseApiRateLimit, setBrowseApiRateLimit] = useState({
    limit: "------",
    remaining: "------"
  });
  const [apiRateLimitResetTime, setApiRateLimitResetTime] = useState("------");

  const [reportingSelectedStore, setReportingSelectedStore] = useState([]);
  const [reportingSelectedTimeFrame, setReportingSelectedTimeFrame] = useState({
    start: null,
    end: null
  });
  const [reportingProgress, setReportingProgress] = useState(0);

  const [reportingDialogBoxOpen, setReportingDialogBoxOpen] = useState(false);

  useEffect(() => {
    dispatch(getEbayApiRateLimitApi());
    dispatch(getStoresApi({ type: 'id-pairs', marketplace: "ebay" }));
  }, []);

  useEffect(() => {
    setTradingApiRateLimit(ebayApiRateLimitApi.tradingApiRateLimit);
    setBrowseApiRateLimit(ebayApiRateLimitApi.browseApiRateLimit);
    setApiRateLimitResetTime(ebayApiRateLimitApi.resetTime);
  }, [ebayApiRateLimitApi]);

  useEffect(() => {
    socket.on("reportingProgress", (data) => {
      setReportingProgress(data.progress);
    });

    return () => {
      socket.off("reportingProgress");
    };
  }, []);

  return (
    <Box m="1.5rem 2.5rem" pb="2rem">
      <Header title="Dashboard" subtitle="" />
      <Box
        m="1.5rem 0 0.5rem 0"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            width: "32%",
            backgroundColor: theme.palette.primary[500],
            padding: "50px",
            diplay: "flex",
            borderRadius: "15px"
          }}
        >
          <Typography fontWeight="bold" variant="h3" p="3px" mb="50px">
            eBay API Rate Limit
          </Typography>
          <Typography fontWeight="bold" variant="h4" p="3px" mb="20px">
            Trading API
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography fontWeight="bold" variant="h5" p="3px" mb="5px">
              LIMIT :
            </Typography>
            <Typography fontWeight="bold" variant="h5" p="3px" mb="5px">
              {tradingApiRateLimit?.limit}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography fontWeight="bold" variant="h5" p="3px" mb="5px">
              RAMAINING :
            </Typography>
            <Typography fontWeight="bold" variant="h5" p="3px" mb="10px">
              {tradingApiRateLimit?.remaining}
            </Typography>
          </Box>
          <LinearProgress variant="determinate" color="secondary" value={((tradingApiRateLimit?.limit - tradingApiRateLimit?.remaining) / tradingApiRateLimit?.limit) * 100} />

          <Typography fontWeight="bold" variant="h3" p="3px" mb="20px" mt="20px">
            Browse API
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography fontWeight="bold" variant="h5" p="3px" mb="5px">
              LIMIT :
            </Typography>
            <Typography fontWeight="bold" variant="h5" p="3px" mb="5px">
              {browseApiRateLimit?.limit}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography fontWeight="bold" variant="h5" p="3px" mb="5px">
              RAMAINING :
            </Typography>
            <Typography fontWeight="bold" variant="h5" p="3px" mb="10px">
              {browseApiRateLimit?.remaining}
            </Typography>
          </Box>
          <LinearProgress variant="determinate" color="secondary" value={((browseApiRateLimit?.limit - browseApiRateLimit?.remaining) / browseApiRateLimit?.limit) * 100} />

          <Typography fontWeight="bold" variant="h4" p="3px" mb="20px" mt="20px">
            Reset Date/Time
          </Typography>
          <Typography fontWeight="bold" variant="h5" p="3px" mt="20px">
            {apiRateLimitResetTime}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "42%",
            backgroundColor: theme.palette.primary[500],
            padding: "50px",
            diplay: "flex",
            borderRadius: "15px"
          }}
        >
          <Typography fontWeight="bold" variant="h3" p="3px" mb="50px">
            PNL Reporting
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "25px",
              flexDirection: "column"
            }}
          >
            <FormControl sx={{ width: "100%" }}>
              <InputLabel
                id="select-store-label"
                color="secondary"
              >Stores</InputLabel>
              <Select
                color="secondary"
                labelId="select-store-label"
                id="select-store"
                value={reportingSelectedStore}
                label="Stores"
                onChange={(e) => setReportingSelectedStore(e.target.value)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {storeIdPairs &&
                  storeIdPairs.map((store) => (
                    <MenuItem key={store._id} value={store._id}>
                      {store.storeName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <DatePicker
                sx={{
                  width: "45%"
                }}
                label="Start"
                color="secondary"
                value={reportingSelectedTimeFrame.start}
                onChange={(value) => setReportingSelectedTimeFrame({
                  ...reportingSelectedTimeFrame,
                  start: value
                })}
              />
              <DatePicker
                sx={{
                  width: "45%"
                }}
                label="End"
                color="secondary"
                value={reportingSelectedTimeFrame.end}
                onChange={(value) => setReportingSelectedTimeFrame({
                  ...reportingSelectedTimeFrame,
                  end: value
                })}
              />  
            </Box>
            {/* <TextField
              color="secondary"
              label="Time Frame In Days"
              variant="outlined"
              sx={{
                width: "40%"
              }}
              value={reportingSelectedTimeFrame}
              onChange={(e) => setReportingSelectedTimeFrame(e.target.value)}
              type="number"
              inputProps={{ min: 1, max: 60 }}
            /> */}
            {
              reportingProgress
                ? <Box
                  sx={{
                    marginLeft: "-15px",
                    marginRight: "15px"
                  }}
                >
                  <ProgressBar
                    width={"100%"}
                    progress={reportingProgress}
                    text="Generating Report"
                  />
                </Box>
                : <LoadingButton
                  color="secondary"
                  variant="contained"
                  sx={{
                    width: "100%",
                    padding: "15px 15px",
                    whiteSpace: 'nowrap',
                    fontSize: '12px',
                  }}
                  onClick={() => setReportingDialogBoxOpen(true)}
                >
                  Generate Report
                </LoadingButton>
            }
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              {/* <LoadingButton
                variant="contained"
                color="secondary"
                sx={{
                  width: "48%"
                }}
                onClick={() => dispatch(downloadReportingApi("store-pnl-report.csv"))}
                loading={downloadReportingStatus === "pending"}
                startIcon={<Download color="black" />}
              >
                Store Report
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="secondary"
                sx={{
                  width: "48%"
                }}
                onClick={() => dispatch(downloadReportingApi("items-pnl-report.csv"))}
                loading={downloadReportingStatus === "pending"}
                startIcon={<Download color="black" />}
              >
                Items Report
              </LoadingButton> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <DialogBox
        open={reportingDialogBoxOpen}
        onClose={() => setReportingDialogBoxOpen(false)}
        onConfirm={() => {
          dispatch(initializeReportingApi({ stores: [reportingSelectedStore], timeFrame: reportingSelectedTimeFrame }));
          setReportingDialogBoxOpen(false);
        }}
        titleText="Confirmation"
        messageText="Are you sure you wish to generate this report?"
        confirmText="Confirm"
      />
    </Box>
  );
};

export default Dashboard;
